@import "../../../sass/colors";

.nav-component {
    display: flex;
    justify-content: center;
    &__link-container:first-child &__link {
        border-radius: 25px 0px 0px 25px; }
    &__link-container:last-child &__link {
        border-radius: 0px 25px 25px 0px; }
    &__link {
        display: inline-block;
        font-size: 30px;
        font-weight: 700;
        padding: 5px 22px;
        box-sizing: border-box;
        white-space: nowrap;
        transition: .2s ease background-color, color;
        height: 100%; }
    &__link:hover {
        background-color: $bg-color-highlight;
        color: $font-color-darker-dark; }
    &__link--selected {
        background: $bg-color-selected !important;
        color: $font-color-lighter-light !important; }
    .jp-font {
        font-size: 23px !important; } }

@media only screen and (max-width: 1024px) {
    .nav-component {
        display: none; } }
