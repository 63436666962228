//Importing fonts
@import "./sass/fonts";
@import "./sass/colors";

body {
  margin: 0;
  padding: 0;
  background-color: white;
  overflow-x: hidden; }

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
  // &__blank-space-height
  //   width: 100%
 }  //   height: 176px
.App--noscroll {
  overflow-y: hidden; }

.App:before {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-image: url("./assets/background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  z-index: -1; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  color: black; }

p {
  margin: 0;
  padding: 0; }

@media only screen and (max-width: 1300px) {
  .App {
    &__blank-space-height {
      height: 0px; } } }
