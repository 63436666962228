.home-page {
    margin: 0px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    &__video-container {
        width: 50%;
        margin-top: 20px; }
    &__video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; } }

    &__container {
        width: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        z-index: 1;
        padding: 10px 0px; }
    &__container::before {
        content: "";
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000044;
        background-image: url("../../assets/background-pages/fondonuevo.webp");
        background-size: clamp(1200px, 150%, 150%) auto;
        background-repeat: repeat;
        background-position: bottom right; } }


@media only screen and (max-width: 1500px) {
    .home-page {
        &__video-container {
            width: 70%; } } }
@media only screen and (max-width: 480px) {
    .home-page {
        &__video-container {
            width: 90%; } } }
