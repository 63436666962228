.footer-component {
    width: 100%;
    height: 150px;
    background-image: url("../../assets/header/banner3new.webp");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: auto;
    &__text-big, &__text-small {
        position: relative;
        z-index: 2; }
    &__text-small {
        font-size: 14px;
        margin-top: 2px; }
    .support-component {
        z-index: 2;
        margin-top: 10px;
        text-align: center;
        display: none; }
    .support-component__donation-button {
        width: 180px; } }

.footer-component:after {
    background-color: rgba(33, 15, 30, 0.87);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1; }

@media only screen and (max-width: 1024px) {
    .footer-component {
        .support-component {
            display: inline !important; } } }
@media only screen and (max-width: 500px) {
    .footer-component {
        overflow-y: hidden; } }
