.our-team-page {
    opacity: 0; //GSAP start
    margin: 0px 0px;
    &__team-wrapper:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000044;
        background-image: url("../../assets/background-pages/main-background.webp");
        background-repeat: repeat;
        opacity: .28;
        z-index: -1; }
    &__team-wrapper {
        position: relative;
        padding: 10px 0px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center; }
    &__profile-wrapper {
        position: relative;
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        margin-top: 30px; }
    &__title {
        text-align: center;
        width: 100%;
        font-size: 54px;
        font-weight: normal;
        padding: 10px;
        box-sizing: border-box; }
    .profile-component {
        margin: 0px 20px 30px; }
    &__main-image {
        transform: translateY(20px); } }

@media only screen and (min-width: 1800px) {
    .our-team-page {
        &__profile-wrapper {
            width: 60%; } } }
@media only screen and (max-width: 1080px) {
    .our-team-page {
        &__main-image {
            display: none; } } }
@media only screen and (max-width: 600px) {
    .our-team-page {
        &__title {
            font-size: 35px; } } }
