.music-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &__block-container {
        min-height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: #FFEFC7;
        padding: 10px 0px;
        box-sizing: border-box;
        z-index: 2; }
    &__block-container::after {
        background-image: url("../../assets/background-pages/fondo1music.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: ""; }
    &__block-wrapper {
        position: relative;
        width: clamp(350px, 900px, 100%);
        margin: 60px 20px;
        padding: 60px 90px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center; }
    &__title {
        text-align: center;
        font-size: 30px;
        letter-spacing: .5ch;
        font-weight: 700; }
    &__logos-bottom {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        img {
            margin-right: 15px; } }
    &__song-info-wrapper {
        display: flex;
        box-sizing: border-box;
        flex-basis: auto; }
    &__song-info-one {
        width: 50%;
        margin-right: 15px;
        align-items: stretch;
        display: flex;
        flex-direction: column; }
    &__song-info-two {
        width: 50%;
        align-items: stretch;
        display: flex;
        flex-direction: column; }
    &__album-cover {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        img {
            width: 100%;
            height: auto;
            display: block; } }
    &__song-title {
        text-align: center;
        border-radius: 5px;
        h1 {
            margin: 0px;
            padding: 0px; }
        .jp-font {
            font-size: 25px; } }
    &__song-description {
        height: 100%;
        border-radius: 5px;
        margin-top: 15px;
        padding: 15px 20px;
        box-sizing: border-box;
        position: relative;
        font-size: 14px; }
    &__buttons {
        display: flex;
        margin-top: 15px;
        position: relative; }
    &__teru::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000; }
    &__button {
        width: 100%;
        padding: 5px 5px;
        text-align: center;

        border-radius: 5px;
        h1 {
            margin: 0;
            font-size: 38px; } }
    &__button:nth-last-child(1) {
        margin-left: 15px; }

    &__teru-container {
        position: static;
        min-width: 310px; }
    &__logos-top {
        z-index: 1;
        position: absolute;
        right: 0;
        display: none;
        justify-content: center;
        margin-top: 30px;
        img {
            margin-right: 15px;
            width: 60px; } }
    &__teru-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex; }
    &__teru {
        width: 160px;
        pointer-events: none;
        position: relative;
        z-index: 1; }
    &__globo {
        width: 110px;
        align-self: flex-end;
        position: absolute;
        bottom: 0;
        img {
            width: 110px;
            align-self: flex-end; } }
    &__paragraph {
        white-space: pre-wrap; }
    // &__paragraph:nth-child(4)
    //     margin-bottom: 15px
    // &__paragraph:nth-child(9)
 }    //     margin-bottom: 15px

@media only screen and (max-width: 900px) {
    .music-page {
        &__song-info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center; }
        &__song-info-one {
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px; }
        &__song-info-two {
            width: 100%; }
        &__block-wrapper {
            width: 70%; }

        &__teru-container {
            position: relative;
            height: auto;
            margin-top: 10px; }
        &__teru-wrapper {
            position: relative; }
        &__teru {
            width: 160px; }
        &__globo {
            width: 110px;
            align-self: flex-end; }
        &__logos-top {
            display: flex; }

        &__buttons {
            flex-direction: column;
            margin-top: 0px; }
        &__button:nth-last-child(1) {
            margin-left: 0px;
            margin-top: 10px; }
        .disappear {
            display: none; } } }
@media only screen and (max-width: 750px) {
    .music-page {
        &__block-wrapper {
            width: 90%; } } }
@media only screen and (max-width: 550px) {
    .music-page {
        &__block-wrapper {
            width: 100%;
            padding: 40px 40px; }
        &__teru-container {
            min-width: 100%; }
        &__logos-top {
            img {
                width: 50px; } } } }
@media only screen and (max-width: 350px) {
    .music-page {
        &__teru {
            width: 130px; }
        &__globo {
            width: 90px;
            align-self: flex-end; }
        &__logos-top {
            display: none; }
        .disappear {
            display: flex; } } }
