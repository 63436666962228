@import "../../sass/colors";
.header-component {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    border: 8px solid $bg-color-dark;
    z-index: 3;
    box-sizing: border-box;
    flex-direction: column;
    &__lang-picker-mobile {
        display: none; }
    &__background-purple {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(33, 15, 30, 0.87); }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: yellow;
        display: flex;
        div {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            justify-content: center; }
        div:nth-child(1) {
            background-image: url("../../assets/header/banner1new.webp");
            width: 33%;
            height: 100%; }
        div:nth-child(2) {
            background-image: url("../../assets/header/banner2new.webp");
            width: 67%;
            height: 100%; } }
    &__logo-side {
        width: 100% !important;
        height: 110px;
        position: relative;
        display: flex;
        box-sizing: border-box;
        // background-image: url("../../assets/header/banner1.webp")
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        img {
            height: auto;
            z-index: 2;
            box-sizing: border-box;
            align-self: flex-end;
            width: clamp(300px, 21%, 21%) !important;
            transform: translate(0, 0);
            margin-bottom: 20px; } }
    &__navigation-side {
        position: relative;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 20px;
        box-sizing: border-box; }
    &__navigation-container {
        display: inline-block;
        z-index: 2; }
    &__navigation-side-bellow {
        display: flex;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 10px;
        justify-content: space-between; }
    .lang-picker-component {
        width: 100%; }
    &__horizontal-buttons {
        margin-top: 10px;
        display: none; }
    .support-component {
        display: flex;
        flex-direction: column;
        margin-top: 10px; }
    .support-component__donation-button {
        width: 180px; }
    &__buttons {
        position: absolute;
        z-index: 1;
        height: 100%;
        right: 0;
        margin-right: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .header-component__donation-buttons {
            margin-top: 10px; } } }

@media only screen and (max-width: 1450px) {
    .header-component {
        .support-component {
            display: flex;
            flex-direction: row;
            margin-top: 10px; }
        .support-component__donation-button {
            margin-left: 10px; }
        &__buttons {
            display: none; }
        &__navigation-container {
            width: 100%; }
        &__navigation-side-bellow {
            flex-direction: column; }
        &__horizontal-buttons {
            display: inline;
            width: 100%;
            display: flex;
            justify-content: center; }
        &__lang-picker-mobile {
            display: flex;
            margin-left: 20px;
            align-items: center; }
        &__lang-picker-desktop {
            display: none; }
        .links-component {
            width: 100%;
            display: flex;
            justify-content: center; }
        .donations-component {
            margin-bottom: 10px; }
        .lang-picker-component {
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center; } } }

@media only screen and (max-width: 1024px) {
    .header-component {
        .support-component {
            display: none !important; } } }

@media only screen and (max-width: 550px) {
    .header-component {
        .support-component {
            display: flex;
            flex-direction: column !important; }
        &__background {
            div:nth-child(2) {
                display: none; }
            div:nth-child(1) {
                width: 100%; } } } }
@media only screen and (max-width: 480px) {
    .header-component {
        &__horizontal-buttons {
            justify-content: flex-start; }
        &__logo-side {
            img {
                width: clamp(250px, 21%, 21%) !important; } } } }
