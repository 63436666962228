$font-color-dark: #696969;
$font-color-darker-dark: #353535;
$font-color-lighter-light: #eee8d8;
$font-color-light: white;
$font-color-highlight: #999997;
$font-color-body-light: #FFEFC7;

$bg-color-main: #3b072d;
$bg-color-dark: #1A1320;
$bg-color-lighter-dark: #2b1928;
$bg-color-light: #eee8d8;
$bg-color-lighter-light: #FFFCF5;
$bg-color-darker-light: #FFEFC7;
$bg-color-highlight: #C9C1B6;
$bg-color-selected: #5a4c52;

body {
    color: $bg-color-dark; }

.font-color-dark {
    color: $font-color-dark; }
.font-color-light {
    color: $font-color-light; }
.font-color-lighter-light {
    color: $font-color-lighter-light; }
.font-color-highlight {
    color: $font-color-highlight; }
.font-color-body-light {
    color: $font-color-body-light; }

.bg-color-dark {
    background-color: $bg-color-dark; }
.bg-color-lighter-dark {
    background-color: $bg-color-lighter-dark; }
.bg-color-light {
    background-color: $bg-color-light; }
.bg-color-lighter-light {
    background-color: $bg-color-lighter-light; }
.bg-color-highlight {
    background-color: $bg-color-highlight; }
.bg-color-selected {
    background-color: $bg-color-selected; }
.bg-color-darker-light {
    background-color: $bg-color-darker-light; }
