.our-team-component {
    &__team-wrapper {
        position: relative;
        padding: 10px 0px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center; }
    &__profile-wrapper-kyliesander {
        max-width: 800px; }
    &__profile-wrapper {
        position: relative;
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        margin-top: 30px;
        img {
            width: auto;
            height: 500px;
            transform: translateX(+35px); } }
    &__title {
        text-align: center;
        width: 100%;
        font-size: 54px;
        font-weight: normal;
        padding: 10px;
        box-sizing: border-box; }
    .profile-component {
        margin: 0px 20px 30px; } }

@media only screen and (min-width: 1800px) {
    .our-team-component {
        &__profile-wrapper {
            width: 60%; } } }
@media only screen and (max-width: 1080px) {
    .our-team-component {
        &__main-image {
            display: none; } } }
@media only screen and (max-width: 600px) {
    .our-team-component {
        &__title {
            font-size: 35px; } } }
