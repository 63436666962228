.about-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    background-color: #FFEFC7;
    position: relative;
    &__text-container {
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
        z-index: 1;
        padding: 10px 0px;
        box-sizing: border-box; }
    &__text-wrapper {
        position: relative;
        z-index: 2;
        max-width: 700px;
        margin: 40px 20px;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 3px 3px 8px 0px #00000044; }
    &__title {
        text-align: center;
        font-size: 18px;
        letter-spacing: .25ch; }
    &__paragraph {
        font-size: 20px;
        margin-top: 20px; }
    &__team-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
            font-size: 32px;
            font-weight: bolder;
            padding: 10px 20px;
            border-radius: 8px;
            box-shadow: 3px 3px 5px 0px #00000044; } }
    &__team-image {
        width: 90%;
        max-width: 700px; } }
.about-page::after {
    background-image: url("../../assets/background-pages/fondonuevo.webp");
    background-repeat: repeat;
    background-size: clamp(1200px, 150%, 150%) auto;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    top: 0; }

@media only screen and (max-width: 1024px) {
    .about-page {
        &__title {
            font-size: 16px; }
        &__paragraph {
            font-size: 18px; } } }
@media only screen and (max-width: 600px) {
    .about-page {
        &__text-wrapper {
            margin: 40px 0px; }
        &__paragraph {
            font-size: 16px; } } }
