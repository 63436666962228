@import "../../../sass/colors";

.sidenav-component {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 888;
    background-color: #24101ffb;
    height: 100vh;
    max-width: 400px;
    width: 100%;
    text-align: center;
    font-size: 34px;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 100px;
    &__link-container:first-child {
        margin-top: 100px; }
    &__link-container + &__link-container {
        margin-top: 10px; }
    &__link {
        opacity: .6;
        transition: .3s ease opacity; }
    &__link--selected {
        opacity: 1; }
    &__link:hover {
        opacity: 1; } }
.sidenav-component--not-visible {
    display: none; }


@media only screen and (min-width: 1024px) {
    .sidenav-component {
        display: none !important; }
    .sidenav-component--visible {
        display: none !important; } }

@media only screen and (orientation: portrait) and (max-height: 900px) {
    .sidenav-component--visible {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 200px;
        &__link-container:first-child {
            margin-top: 120px; }
        &__link-container + &__link-container {
            margin-top: 0px; } } }
