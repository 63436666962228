.profile-component {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    &__picture {
        height: 300px; }
    &__text-wrapper {
        text-align: center;
        width: 220px;
        height: 100%;
        padding: 30px 15px 30px;
        border-radius: 5px;
        box-shadow: 3px 3px 8px 0px #00000031; }
    .weight-bold {
        font-weight: bold; }
    .weight-normal {
        font-weight: normal; }
    .font-size-l {
        font-size: 32px; }
    .font-size-sm {
        font-size: 18px; }
    &__text-description {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 4px; } }
.profile-component--language {
    .profile-component__text-name {
        position: relative;
        transform: translateY(-4px);
        z-index: 3; }
    .profile-component__picture {
        transform: translateY(75px); } }
.profile-component--main {
    .profile-component__picture {
        display: none; } }

.invisible {
    display: none; }

@media only screen and (max-width: 1080px) {
    .profile-component--main {
        .profile-component__picture {
            display: inline; } } }
