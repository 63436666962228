.contact-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &__container {
        min-height: 600px;
        display: flex;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        z-index: 1; }
    &__container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000044;
        background-image: url("../../assets/background-pages/fondonuevo.webp");
        background-size: clamp(1200px, 150%, 150%) auto;
        z-index: -1; }
    &__wrapper {
        position: relative;
        padding: 10px 0px;
        margin: 40px 0px;
        z-index: 1;
        width: 100%;
        box-sizing: border-box; }
    &__title {
        text-align: center;
        width: 100%;
        font-size: 54px;
        font-weight: bold;
        padding: 10px;
        box-sizing: border-box; }
    &__email-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
    &__email {
        display: inline-block;
        border-radius: 10px;
        font-size: 54px;
        padding: 40px 80px;
        font-weight: bold;
        box-shadow: 3px 3px 8px 0px #00000031;
        cursor: pointer;
        p {
            text-align: center;
            margin-bottom: 20px; }
        .links-component {
            display: flex;
            justify-content: center; } }
    &__copied {
        opacity: 0;
        position: fixed;
        bottom: 30px;
        right: 30px;
        font-size: 28px;
        font-weight: bolder;
        padding: 10px 30px;
        z-index: 1000;
        border-radius: 5px;
        box-shadow: 3px 3px 5px 0px #00000031; } }

@media only screen and (max-width: 1024px) {
    .contact-page {
        &__container {
            min-height: 400px; }
        &__email {
            font-size: 38px;
            padding: 40px 60px; }
        &__title {
            font-size: 38px; } } }
@media only screen and (max-width: 600px) {
    .contact-page {
        &__email {
            font-size: calc(0.6em + 3vw);
            padding: 40px 40px; }

        &__title {
            font-size: 35px; } } }
@media only screen and (max-width: 350px) {
    .contact-page {
        &__email {
            p {
                display: none; } }
        &__email-text:before {
            font-size: 35px;
            text-align: center;
            width: 100%;
            content: "Click to Copy 📧";
            display: block;
            margin-bottom: 30px; } } }
