.characters-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &__block-container {
        min-height: 450px;
        display: flex;
        overflow-x: hidden;
        //justify-content: center
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: #FFEFC7;
        padding: 10px 0px;
        box-sizing: border-box;
        z-index: 2; }
    &__block-container::after {
        background-image: url("../../assets/background-pages/fondonuevo.webp");
        background-repeat: repeat;
        background-position: 100%;
        background-size: clamp(1200px, 200%, 200%) auto;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        z-index: -1;
        content: ""; }
    &__block-wrapper {
        position: relative;
        width: 550px;
        height: 250px;
        margin: 60px 20px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center; }
    &__title {
        text-align: center;
        font-size: 30px;
        letter-spacing: .5ch;
        font-weight: 700; } }
