.lyrics-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &__container {
        min-height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: #FFEFC7;
        padding: 10px 0px;
        box-sizing: border-box;
        z-index: 2; }
    &__container::after {
        background-image: url("../../assets/background-pages/fondo1music.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: ""; }
    &__wrapper {
        position: relative;
        width: clamp(350px, 850px, 100%);
        margin: 30px 20px;
        padding: 60px 90px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    &__title {
        text-align: center;
        border-radius: 5px;
        padding: 4px 5px;
        width: 100%;
        max-width: 360px;
        font-size: 35px;
        box-sizing: border-box;
        h1 {
            margin: 0px;
            padding: 0px; }
        .jp-font {
            font-size: 25px; } }
    &__album-cover {
        margin: 30px 0;
        width: 100%;
        max-width: 400px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 14px solid #eee8d8; }


    &__music-logos {
        margin-top: 30px; }
    &__lyrics-container {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        padding: 30px 20px;
        box-sizing: border-box;
        position: relative; }
    &__lyrics-title-container {
        z-index: 1;
        margin-bottom: 20px;
        h1, h2, h3 {
            margin: 0;
            padding: 0; }
        h1 {
            font-size: 40px; }
        h2 {
            font-size: 30px; } }
    &__lyrics {
        text-align: left;
        width: 70%;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        z-index: 1; }
    &__paragraph {
        white-space: pre-wrap; }
    &__lyrics-buttons {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: space-between;
        font-size: 100px;
        align-items: center; }
    &__lyrics-button {
        padding: 0 10px;
        color: black;
        width: 100px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box; }
    &__lyrics-button:hover {
        cursor: pointer; }
    &__lyrics-button-left:hover {
        background: linear-gradient(90deg, #580d4e2a 0%, #24052100 100%); }
    &__lyrics-button-right:hover {
        background: linear-gradient(270deg, #580d4e2a 0%, #24052100 100%); }
    .alice-carousel__dots {
        position: absolute;
        bottom: 10px;
        width: 100%; }
    &__music-logos {
        width: 100%;
        display: flex;
        justify-content: center;
        a {
            margin: 0 10px; } } }

@media only screen and (max-width: 720px) {
    .lyrics-page {
        &__lyrics {
            width: 80%;
            padding: 20px; }
        &__wrapper {
            padding: 30px 40px; } } }
@media only screen and (max-width: 450px) {
    .lyrics-page {
        &__wrapper {
            padding: 30px 20px; } } }
