.synopsis-component {
    width: 100%;
    &__text-container {
        min-height: 700px;
        display: flex;

        align-items: center;
        position: relative;
        // background-color: #FFEFC7
        flex-direction: column;
        z-index: 1;
        padding: 10px 0px;
        box-sizing: border-box; }
    // &__text-container::after
    //     background-image: url("../../../assets/background-pages/main-background.webp")
    //     background-repeat: no-repeat
    //     background-size: cover
    //     background-position: 100%
    //     position: absolute
    //     width: 100%
    //     height: 100%
    //     opacity: .28
    //     z-index: -1
    //     content: ""
    &__text-wrapper {
        position: relative;
        z-index: 2;
        max-width: 550px;
        margin: 40px 20px;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 3px 3px 8px 0px #00000044; }
    &__title {
        text-align: center;
        font-size: 18px;
        letter-spacing: .25ch; }
    &__paragraph {
        font-size: 20px;
        margin-top: 20px; } }


@media only screen and (max-width: 1024px) {
    .synopsis-component {
        &__title {
            font-size: 16px; }
        &__paragraph {
            font-size: 18px; } } }
@media only screen and (max-width: 600px) {
    .synopsis-component {
        &__text-wrapper {
            margin: 40px 0px; }
        &__paragraph {
            font-size: 16px; } } }
