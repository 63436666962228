.help-component {
    width: 100%;
    &__container {
        min-height: 700px;
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
        z-index: 1;
        padding: 10px 0px;
        box-sizing: border-box; }
    &__container::before {
        content: "";
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000044;
        background-image: url("../../../assets/background-pages/fondonuevo.webp");
        background-size: clamp(1200px, 150%, 150%) auto;
        background-repeat: repeat;
        background-position: bottom right; }
    &__wrapper {
        position: relative;
        z-index: 2;
        max-width: 700px;
        margin: 40px 20px;
        box-sizing: border-box;
        img {
            width: 100%; } }
    &__title {
        text-align: center;
        font-size: 18px;
        letter-spacing: .25ch; }
    &__paragraph {
        font-size: 20px;
        margin-top: 20px; } }



@media only screen and (max-width: 1024px) {
    .synopsis-component {
        &__title {
            font-size: 16px; }
        &__paragraph {
            font-size: 18px; } } }
@media only screen and (max-width: 600px) {
    .synopsis-component {
        &__text-wrapper {
            margin: 40px 0px; }
        &__paragraph {
            font-size: 16px; } } }
