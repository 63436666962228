@import "../../../sass/colors.sass";

.burger-menu-component {
    position: fixed;
    top: 35px;
    right: 25px;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    padding: 18px 14px;
    box-sizing: border-box;
    display: none;
    flex-direction: column;
    background-color: $bg-color-darker-light;
    justify-content: space-between;
    cursor: pointer;
    z-index: 9999;
    div {
        height: 3px;
        background-color: $bg-color-dark; } }
@media only screen and (max-width: 1024px) {
    .burger-menu-component {
        display: flex; } }
@media only screen and (max-width: 480px) {
    .burger-menu-component {
        top: 110px;
        right: 10px; } }
