.read-manga-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &__block-container {
        min-height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: #FFEFC7;
        padding: 10px 0px;
        box-sizing: border-box;
        z-index: 2; }
    &__block-container::after {
        background-image: url("../../assets/background-pages/teru.webp");
        background-repeat: repeat;
        background-size: cover;
        background-position: 30% 100%;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .28;
        z-index: -1;
        content: ""; }
    &__block-wrapper {
        position: relative;
        z-index: 1;
        width: clamp(300px, 80vw, 530px);
        height: 300px;
        margin: 60px 20px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center; }
    &__title {
        text-align: center;
        font-size: 30px;
        letter-spacing: .5ch;
        font-weight: 700; }

    &__logos {
        display: flex;
        justify-content: space-between;
        margin-top: 30px; }
    &__logo {
        align-self: center;
        &-webtoon {
            border-radius: 100%; }
        &-pixiv, &-artstreet {
            border-radius: 5px; } } }

@media only screen and (max-width: 1300px) {
    .read-manga-page {
        &__block-container::after {
            background-size: 150% auto;
            background-position: 50% 100%; } } }
@media only screen and (max-width: 800px) {
    .read-manga-page {
        &__block-container::after {
            background-size: 2000px auto;
            background-position: 30% 100%; } } }
@media only screen and (max-width: 600px) {
    .read-manga-page {
        &__logos {
            display: flex;
            flex-direction: column; }
        &__logo {
            margin-top: 15px; }
        &__block-wrapper {
            box-sizing: content-box; } } }
