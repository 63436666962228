@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Open+Sans:wght@400&family=Amatic+SC:wght@400;700&display=swap');

@font-face {
    font-family: DINNEXT;
    src: url("../assets/fonts/DINNextRoundedLTPro-Light.woff");
    font-display: swap; }

.font-light {
    font-family: 'Open Sans', sans-serif; }
.font-body {
    font-family: 'Nunito', sans-serif; }
.font-forgotten {
    font-family: DINNEXT; }
.font-seconds {
    font-family: 'Amatic SC', cursive; }
