.profile-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    overflow: hidden;
    &__container {
        min-height: 700px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: #FFEFC7;
        flex-direction: column;
        z-index: 1;
        padding: 10px 0px;
        box-sizing: border-box;
        width: 100%; }

    &__container::after {
        background-image: url("../../assets/background-pages/fondonuevo.webp");
        background-repeat: repeat;
        background-position: center;
        background-size: clamp(1200px, 150%, 150%) auto;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: "";
        top: 0; }
    &__wrapper {
        box-sizing: border-box;
        margin-bottom: 30px; }
    &__text-wrapper {
        position: relative;
        z-index: 2;
        width: clamp(300px, 80vw, 1000px);
        padding: 80px 40px 40px 40px;
        box-sizing: border-box;
        box-shadow: 3px 3px 8px 0px #00000044;
        border-radius: 5px;
        font-size: 20px; }
    &__text-top {
        display: flex;
        justify-content: space-between;
        text-align: right;
        align-items: flex-end;
        margin-bottom: 10px;
        font-size: 16px; }
    &__role {
        font-size: 20px;
        font-weight: 700; }
    &__top-info {
        position: relative;
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        z-index: 3;
        height: 335px; }
    &__top-info-wrapper {
        display: flex;
        align-items: center;
        z-index: 1;
        width: 400px;
        position: absolute;
        left: -150px;
        top: -30px; }
    &__profile-picture {
        width: 430px;
        position: relative;
        z-index: 2;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        -moz-user-drag: none;
        -khtml-user-drag: none;
        -webkit-user-drag: none;
        user-drag: none; }
    &__name {
        position: relative;
        width: 340px;
        text-align: center;
        padding: 10px 0;
        border-radius: 5px;
        box-shadow: 3px 3px 8px 0px #00000044;
        box-sizing: border-box;
        p {
            font-size: 40px;
            font-weight: bold;
            padding-left: 35px; } }
    &__top-info-text {
        transform: translate(-140px, -30px);
        z-index: 1;
        position: relative;
        text-align: center; }
    &__secondary-image {
        width: 500px;
        height: auto;
        transform: translateX(50px); }
    &__mobile-secondary {
        display: none; }
    &__mobile-networks {
        display: none; }
    &__position {
        font-size: 16px;
        margin-top: 10px; } }

@media only screen and (max-width: 1200px) {
    .profile-page {
        &__secondary-image {
            display: none; }
        &__mobile-secondary {
            display: inline;
            width: clamp(200px, 100%, 700px); }
        &__mobile-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20px; }
        &__top-info-text {
            transform: translateX(-100px); } } }
@media only screen and (max-width: 700px) {
    .profile-page {
        &__top-info-text {
 } } }            //transform: translateX(-160px)
@media only screen and (max-width: 600px) {
    .profile-page {
        &__mobile-networks {
            display: inline; }
        &__networks {
            display: none; }
        &__top-info-text {
            transform: translateX(-90px); }
        &__text-top {
            width: 100%;
            justify-content: flex-end;
            font-size: 16px; }
        &__profile-picture {
            width: 350px; }
        &__top-info-wrapper {
            left: -120px;
            top: 40px; }
        &__name {
            position: relative;
            width: 300px; }
        &__text-wrapper {
            font-size: 18px; }
        &__role {
            font-size: 18px;
            font-weight: 700; } } }
@media only screen and (max-width: 450px) {
    .profile-page {
        &__profile-picture {
            width: 300px; }
        &__top-info-text {
            transform: translateX(-100px); }
        &__top-info-wrapper {
            top: -20px; }
        &__top-info {
            height: 180px; } } }
@media only screen and (max-width: 400px) {
    .profile-page {
        &__profile-picture {
            width: 250px; } } }
