.support-page {
    margin: 0px 0px;
    opacity: 0; //GSAP start
    &--not-visible {
        display: none; }
    &__text-container {
        min-height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #FFEFC7;
        flex-direction: column;
        z-index: 1;
        padding: 10px 0px;
        box-sizing: border-box; }
    &__text-container::after {
        background-image: url("../../assets/background-pages/fondonuevo.webp");
        background-repeat: repeat;
        position: absolute;
        background-size: clamp(1200px, 150%, 150%) auto;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: ""; }
    &__text-wrapper {
        position: relative;
        z-index: 2;
        max-width: 550px;
        background-color: white;
        margin: 40px 20px;
        padding: 40px;
        box-sizing: border-box; }
    &__donation-buttons {
        padding: 0px 30px; }
    &__donation-button {
        max-width: 410px;
        width: 100%;
        margin-bottom: 20px; }
    &__kofi, &__patreon {
        display: flex; }
    &__kofi-text, &__patreon-text {
        margin-left: 20px;
        margin-bottom: 10px; }
    &__title {
        font-weight: bold;
        font-size: 21px; }
    &__subtitle {
        font-weight: bold;
        font-size: 18px; }
    &__description, &__main-description, &__team {
        font-size: 18px; }
    &__team {
        text-align: right;
        margin-top: 30px; }
    a {
        text-decoration: underline;
        font-size: 18px; }
    &__patreon-icon, &__kofi-icon {
        width: 100px;
        height: 100px; }
    &__school-image {
        width: 100%;
        margin-bottom: 20px; } }

@media only screen and (max-width: 1024px) {
    .support-page {
        &__title {
            font-size: 16px; } } }
@media only screen and (max-width: 600px) {
    .support-page {
        &__text-wrapper {
            margin: 40px 0px;
            padding: 20px; }
        &__kofi, &__patreon {
            flex-direction: column;
            align-items: center; }
        &__kofi-text, &__patreon-text {
            margin-left: 0px; }
        &__title {
            font-weight: bold;
            font-size: 18px; }
        &__subtitle {
            font-weight: bold;
            font-size: 16px; }
        &__description, &__main-description, &__team {
            font-size: 16px; }
        a {
            text-decoration: underline; }
        &__donation-buttons {
            padding: 0px 10px; } } }
