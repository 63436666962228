@import "../../../sass/colors.sass";

.links-component {
    display: flex;
    flex-flow: wrap;
    padding: 0px 20px;
    box-sizing: border-box;
    &__link {
        transition: .5s ease opacity;
        margin: 0 10px; }
    &__link:hover {
        opacity: .8; }
    &__link + &__link {} }

@media only screen and (max-width: 480px) {
    &__link + &__link {
        margin-left: 5px; }
    img {
        width: 90%; } }
